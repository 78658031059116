<template>
  <div>
    <external-view />
  </div>
</template>

<script>
export default {
  name: 'ExternalIndex',

  components: {
    ExternalView: () => import('./ExternalView')
  }
}
</script>
